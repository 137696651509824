import React, { FC } from 'react';

import { LeftLabeledComponentProps } from './LeftLabeledComponent.props';
import { classes } from 'utils/tailwind';
import { Tooltip } from 'react-daisyui';
import Info from 'assets/SVG/info';

const LeftLabeledComponentView: FC<LeftLabeledComponentProps> = ({
  label,
  children,
  tooltip,
  required = false,
  itemsAlign = 'items-center',
  hide = false,
  className = '',
  labelClassName = '',
  childrenClass = '',
}) => {
  const isLabelUndefined = label === undefined;

  if (hide) {
    return null;
  }

  return (
    <div className={classes(['flex', itemsAlign, className])}>
      {!isLabelUndefined && (
        <div className={classes(['w-[160px]', labelClassName])}>
          <label
            htmlFor={label}
            className={classes([required ? 'required' : ''])}>
            {label}
          </label>
          {tooltip && (
            <Tooltip message={tooltip}>
              <Info />
            </Tooltip>
          )}
        </div>
      )}
      <div className={classes(['w-full', childrenClass])} id={label}>
        {children}
      </div>
    </div>
  );
};

export default LeftLabeledComponentView;

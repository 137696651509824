import { FC, useEffect, useState } from 'react';

import { AdjustStockViewProps } from './AdjustStock.props';
import ArrowRightIcon from 'svg/ArrowRightIcon';
import { useNavigate } from 'react-router-dom';
import AdjustStockForm from 'components/modules/AdjustStockForm/AdjustStockForm.view';

const AdjustStockModal: FC<AdjustStockViewProps> = ({
  itemDetail,
  onHandleVariantChange,
  onSubmit,
  newQuantity,
  currentStock,
  loading,
  onSuccess,
}) => {
  const variantID = itemDetail?.variants[0]._id;

  useEffect(() => {
    onHandleVariantChange(variantID!);
  }, []);

  const reasons = [
    { value: 1, title: 'Internal Transfer' },
    { value: 2, title: 'Restocking' },
    { value: 3, title: 'Found Missing Items' },
    { value: 4, title: 'Inventory Adjustment' },
    { value: 5, title: 'Supplier Restocked' },
    { value: 6, title: 'Customer Return' },
    { value: 7, title: 'Damaged Goods' },
    { value: 8, title: 'Lost' },
    {
      value: 9,
      title: 'Obsolete or Discontinued Product',
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex w-full h-full">
        <div className="flex flex-col py-0 w-full overflow-y-auto space-y-4 items-center">
          {
            <>
              <h3>You're updating the quantity of the following item:</h3>
              <div className="text-gray-700 text-3xl capitalize font-jakarta font-bold">
                "{itemDetail?.name}"
              </div>
              <AdjustStockForm
                isModal
                onSuccess={onSuccess}
                currentStock={currentStock}
                newQuantity={newQuantity}
                variantID={variantID}
                reasons={reasons}
                loading={loading}
                itemDetail={itemDetail}
                onSubmit={onSubmit}
              />
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default AdjustStockModal;

import { FC, useState } from 'react';

import { animated, config, useSpring } from '@react-spring/web';

import { withAuthGuard } from 'components/hoc';
import logo from 'assets/triple20logo.svg';
import powered_by from 'assets/poweredbyXH.svg';
import LogoutIcon from 'assets/SVG/logOutIcon';
import { sideBarMenuItems } from 'constants/sideBarMenuItems';
import SidebarItem from 'components/primitives/SidebarItem';
import { useLogout } from 'hooks/useLogout';

const STYLES = {
  from: {
    x: -300,
    opacity: 0,
  },
  to: {
    x: 0,
    opacity: 1,
  },
  config: config.default,
};

const SideBar: FC = () => {
  const [selectedElementKey, setSelectedElementKey] = useState<string>();
  const styles = useSpring(STYLES);

  const handleLogout = useLogout();
  return (
    <animated.aside
      className="flex shadow-xl z-50 shadow-gray-300 flex-col bg-neutralBg w-[15vw] min-w-60 h-screen"
      style={styles}
      role="navigation">
      <div className="flex flex-1 items-center justify-center max-h-[10vw] min-h-[130px] h-full">
        <h1>
          <img alt="imglogo" src={logo} className="w-48" />
        </h1>
      </div>
      <ul className="flex flex-col items-center gap-7">
        {[...sideBarMenuItems].map(
          ({ title, path, items, Icon }, idx: number) => {
            const KEY = `menu-item-${title}-${idx}`;
            const IS_SELECTED = KEY === selectedElementKey;

            return (
              <SidebarItem
                key={KEY}
                path={path ?? ''}
                title={title}
                Icon={Icon}
                options={items}
              />
            );
          },
        )}
        {/* Logout button */}
        <div
          onClick={handleLogout}
          className="flex btn btn-ghost btn-md h-[60px] font-primary text-primary-350 rounded-none hover:bg-blue-50 hover:text-blue-200 mr-24">
          <LogoutIcon width={25} height={25} />
          Logout
        </div>
      </ul>

      <div className="h-[200px] w-full flex flex-col gap-2 items-center justify-center">
        <div className="h-[100px] w-[120px]">
          {' '}
          <img alt="poweredBy" src={powered_by} />
        </div>
      </div>
    </animated.aside>
  );
};

export default withAuthGuard(SideBar);

import * as yup from 'yup';

export const adjustStockSchema = yup.object().shape({
  variation: yup.string().required('Variation is required'),

  adjustQuantity: yup
    .string()
    .required('Adjust quantity is required')
    .test(
      'is-valid-number',
      'Adjust quantity must be a valid number',
      (value) => {
        const parsedValue = parseFloat(value);
        return !isNaN(parsedValue) && /^-?\d+(\.\d+)?$/.test(value); // Match valid numbers
      },
    ),
  newQuantity: yup
    .number()
    .required('New quantity is required')
    .min(0, 'New quantity cannot be negative'),
  reason: yup.string().required('Reason is required'),
  adjustmentNotes: yup.string().required('Adjustment notes is required'),
});

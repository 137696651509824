import { MutationOptions, useMutation } from '@tanstack/react-query';
import { editOrder } from 'services/shipOrders';

const useEditShipOrder = (
  id: string,
  options?: MutationOptions<any, any, any>,
) => {
  return useMutation({
    mutationFn: (payload: any) =>
      editOrder({
        id,
        payload,
      }),
    mutationKey: ['update-ship-order'],
    ...options,
  });
};

export default useEditShipOrder;

import React, { FC, useEffect, useState } from 'react';
import { useItems } from 'hooks/useItems';
import { useItemDetail } from 'hooks/useItemDetails';
import { useNavigate } from 'react-router-dom';
import { adjutStock } from 'services/items';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastError } from 'utils/toast';
import Toast from 'helperClasses/toastHelper';
import { RoutePaths } from 'constants/routes';
import { Loading } from 'react-daisyui';
import { QUERY_KEYS } from 'queries';
import AdjustStockModal from './AdjustStockModal.view';

interface AdjustStockModalContainerProps {
  itemId: string;
  onSuccess: (values: any) => void;
}

const AdjustStockModalContainer: FC<AdjustStockModalContainerProps> = ({
  itemId,
  onSuccess,
}) => {
  const id = itemId;
  const queryClient = useQueryClient();
  const [locationArray, setLocationArray] = useState<number[]>([]);
  const [variant, setVariant] = useState<string>('');
  const [adjustQuantity, setAdjustQuantity] = useState<number>(0);
  const [newQuantity, setNewQuantity] = useState<number>(0);
  const [currentStock, setCurrentStock] = useState<number>(0);

  const { itemDetail, loading: isItemDetailLoading } = useItemDetail(id!);

  const { items = [], loading: isItemsLoading } = useItems({
    limit: undefined,
    page: undefined,
  });

  useEffect(() => {
    if (
      itemDetail?.inventories &&
      itemDetail?.inventories.length > 0 &&
      itemDetail?.inventories[0].quantity &&
      !isNaN(itemDetail?.inventories[0].quantity)
    )
      setCurrentStock(itemDetail?.inventories[0].quantity);
  }, [itemDetail]);

  useEffect(() => {
    if (!isNaN(currentStock)) {
      setNewQuantity(currentStock + adjustQuantity);
    }
  }, [adjustQuantity, itemDetail]);

  const IncrementLocationArray = () => {
    setLocationArray([...locationArray, locationArray.length + 1]);
  };
  const onDelete = (indexToDelete: number) => {
    const updatedArray = locationArray.filter(
      (_, index) => index !== indexToDelete,
    );
    setLocationArray(updatedArray);
  };
  const { mutate, isPending } = useMutation({
    mutationFn: adjutStock,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['FECTH-DAMAGED-ITEMS'],
      });
      queryClient.invalidateQueries({
        queryKey: ['FECTH-ITEMS'],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ITEM_DETAILS],
      });
      Toast.success('Stock Adjustment Successful');
    },
    onError: (e: any) => {
      const errorMessage =
        e?.response?.data?.error?.message || 'Stock Adjustment failed';
      ToastError({ errorMessage });
    },
  });
  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      reason: parseInt(data.reason),
      adjustQuantity: parseInt(data.adjustQuantity),
      item: id,
      variant: variant,
      warehouse: process!.env!.REACT_APP_WAREHOUSE_ID,
    };
    mutate(payload);
  };

  const onHandleVariantChange = (value: string) => {
    setVariant(value);
  };
  const handleAdjustQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdjustQuantity(Number(e.target.value));
  };

  const isLoading = isItemDetailLoading || isItemsLoading;

  if (isLoading)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loading />
      </div>
    );

  return (
    <AdjustStockModal
      adjustQuantity={adjustQuantity}
      locationArrayData={locationArray}
      incrementLocationArray={IncrementLocationArray}
      onDelete={onDelete}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      onHandleVariantChange={onHandleVariantChange}
      items={items}
      itemDetail={itemDetail}
      handleAdjustQuantity={handleAdjustQuantity}
      newQuantity={newQuantity}
      currentStock={currentStock}
      loading={isPending}
    />
  );
};

export default AdjustStockModalContainer;

import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'queries';
import { fetchItemDetailsById } from 'services/items';

export const useItemDetail = (id: string) => {
  const { data: itemDetail, isLoading: loading } = useQuery({
    queryKey: [QUERY_KEYS.FETCH_ITEM_DETAILS, id],
    queryFn: () => fetchItemDetailsById(id),
    staleTime: Infinity,
  });

  return { itemDetail, loading };
};

import { FC, useRef } from 'react';
import { Formik, Form } from 'formik';
import FormikSelectView from 'components/primitives/FormikSelect/FormikSelect.view';
import LeftLabeledComponent from 'components/primitives/LeftLabeledComponent';
import FormikInput from 'components/primitives/FormikInput';
import FormikTextArea from 'components/primitives/FormikTextArea';
import Button from 'components/primitives/Button';
import { useNavigate } from 'react-router-dom';
import { adjustStockSchema } from './AdjustStock.utils';
import { ToastError } from 'utils/toast';
import Toast from 'helperClasses/toastHelper';
import { queryClient } from 'utils';
import { QUERY_KEYS } from 'queries';
import { adjutStock } from 'services/items';
import { useMutation } from '@tanstack/react-query';

type AdjustStockFormProps = {
  currentStock: number;
  newQuantity: number;
  variantID: string | undefined;
  reasons: { value: number; title: string }[];
  loading: boolean;
  itemDetail?: any;
  onSubmit: (values: any) => void;
  onSuccess: (values: any) => void;
  setFieldValue?: (field: string, value: any) => void;
  isModal?: boolean;
};

const AdjustStockForm: FC<AdjustStockFormProps> = ({
  currentStock,
  newQuantity,
  variantID,
  reasons,
  loading,
  itemDetail,
  isModal,
  onSuccess,
}) => {
  const id = itemDetail._id;
  const variant = variantID;
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { mutate, isPending } = useMutation({
    mutationFn: adjutStock,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['FECTH-DAMAGED-ITEMS'],
      });
      queryClient.invalidateQueries({
        queryKey: ['FECTH-ITEMS'],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ITEM_DETAILS],
      });
      Toast.success('Stock Adjustment Successful');
      onSuccess(data);
    },
    onError: (e: any) => {
      const errorMessage =
        e?.response?.data?.error?.message || 'Stock Adjustment failed';
      ToastError({ errorMessage });
    },
  });
  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      reason: parseInt(data.reason),
      adjustQuantity: parseInt(data.adjustQuantity),
      item: id,
      variant: variant,
      warehouse: process!.env!.REACT_APP_WAREHOUSE_ID,
    };
    mutate(payload);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        adjustQuantity: '',
        currentStock: currentStock,
        variation: variantID,
        newQuantity: currentStock,
        reason: '',
        adjustmentNotes: '',
      }}
      validationSchema={adjustStockSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {({ setFieldValue, errors, submitForm }) => {
        return (
          <Form>
            <div className="flex flex-col gap-2">
              <div className="border rounded-md border-gray-200">
                <div className="border-b  py-2 px-4">
                  <LeftLabeledComponent label="Variation" required>
                    {itemDetail?.variants &&
                      itemDetail?.variants.length > 0 && (
                        <div className="flex justify-end">
                          <div className="w-1/2">
                            <FormikSelectView
                              defaultValue={variantID}
                              name="variation"
                              id="variation"
                              className="w-full">
                              <option value="">Select Variant</option>
                              {itemDetail.variants.map(
                                (val: any, index: number) => (
                                  <option key={index} value={val._id}>
                                    {val?.attributes?.[0]?.attribute}
                                  </option>
                                ),
                              )}
                            </FormikSelectView>
                          </div>
                        </div>
                      )}
                  </LeftLabeledComponent>
                </div>
                <div className="border-b py-2 px-4">
                  <LeftLabeledComponent label="Current Stock">
                    <div className="flex justify-end">
                      <div className="w-1/2">
                        <FormikInput
                          disabled
                          justifyText="text-right"
                          value={currentStock}
                          name="currentStock"
                          type="text"
                        />
                      </div>
                    </div>
                  </LeftLabeledComponent>
                </div>
                <div className="border-b  py-2 px-4">
                  <LeftLabeledComponent label="Qty Adjusted" required>
                    <div className="flex justify-end">
                      <div className="w-1/2">
                        <FormikInput
                          justifyText="text-right"
                          name="adjustQuantity"
                          placeholder="Eg. +10,-10"
                          onInput={(e: any) => {
                            const value = e.target.value;
                            const parsedValue = parseInt(value, 10);
                            const newQuantity = isNaN(parsedValue)
                              ? currentStock
                              : currentStock + parsedValue;
                            setFieldValue('newQuantity', newQuantity);
                          }}
                          type="text"
                        />
                      </div>
                    </div>
                  </LeftLabeledComponent>
                </div>
                <div className="py-2 px-4">
                  <LeftLabeledComponent label="New Quantity">
                    <div className="flex justify-end">
                      <div className="w-1/2">
                        <FormikInput
                          disabled
                          justifyText="text-right"
                          name="newQuantity"
                          type="number"
                        />
                      </div>
                    </div>
                  </LeftLabeledComponent>
                </div>
              </div>

              <FormikSelectView
                name="reason"
                required
                id="reason"
                label="Reason"
                className="w-full">
                <option value="">Select Reason</option>
                {reasons.map((reason, index) => (
                  <option key={index} value={reason.value}>
                    {reason.title}
                  </option>
                ))}
              </FormikSelectView>
              <FormikTextArea
                label="Adjustment Notes"
                required
                name="adjustmentNotes"
                id="adjustmentNotes"
                className="w-full"
                placeholder={'Adjustment Notes'}
              />

              <div className="flex flex-row justify-end gap-4">
                {isModal || (
                  <Button
                    variant="outline"
                    type="button"
                    className="normal-case"
                    onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                )}
                <Button
                  type="button"
                  variant="solid"
                  className={`normal-case ${isModal && 'w-full'}`}
                  onClick={() => {
                    submitForm();
                  }}
                  isLoading={loading}>
                  Adjust Stock
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdjustStockForm;

import { forwardRef, useEffect } from 'react';

import { AlertModalProps } from './AlertModal.props';
import { Modal } from 'react-daisyui';
import ModalReference from 'helperClasses/modalReference';
import Loading from '../Loading';

const AlertModalView = forwardRef<HTMLDialogElement, AlertModalProps>(
  (
    {
      actions,
      infoIcon,
      title,
      description = '',
      isLoading = false,
      showCloseButton = false,
    },
    ref,
  ) => {
    const modal = new ModalReference(ref);

    useEffect(() => {
      if (!showCloseButton) {
        const handleKeyDown = (event: any) => {
          if (event.key === 'Escape') {
            event.preventDefault();
          }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }
    }, [showCloseButton]);

    return (
      <div className="font-sans">
        <Modal
          ref={ref}
          className="flex flex-col items-center justify-between py-12 px-8 relative max-w-lg">
          {showCloseButton && (
            <button
              className="absolute top-0 right-0 m-4"
              onClick={() => modal.close()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
          <div className="flex justify-center">
            {infoIcon && <span className="mb-6">{infoIcon}</span>}
          </div>
          <Modal.Header className="flex font-bold justify-center">
            <h1 className="text-center text-3xl leading-normal">{title}</h1>
          </Modal.Header>
          <Modal.Body className="relative">
            <p className="text-center text-grey-light text-sm">{description}</p>
            {isLoading && <Loading />}
          </Modal.Body>
          <Modal.Actions>
            {actions && (
              <div className="flex justify-center gap-2">{actions}</div>
            )}
          </Modal.Actions>
        </Modal>
      </div>
    );
  },
);

export default AlertModalView;

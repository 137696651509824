import React, { FC } from 'react';

import { HeaderViewProps } from './Header.props';
import { useIsMobile } from 'hooks';
import ArrowLeftIcon from 'svg/ArrowLeftIcon';
import { useNavigate } from 'react-router-dom';
const HeaderView: FC<HeaderViewProps> = ({
  children,
  title,
  enableGoBack = false,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  return (
    (!isMobile && (
      <div className="navbar bg-base-100 shadow-md sticky top-0 z-10 h-16">
        <div className="flex-1 ml-5 font-semibold text-[16px] text-primary font-secondary flex items-center gap-4">
          {enableGoBack && (
            <div className="click-animation" onClick={() => navigate(-1)}>
              <ArrowLeftIcon />
            </div>
          )}
          <span>{title}</span>
        </div>
        {children}
      </div>
    )) || <></>
  );
};

export default HeaderView;

import React, { FC, InputHTMLAttributes } from 'react';
import { FormikTextAreaProps } from './FormikTextArea.props';
import { cn } from 'utils';
import { Textarea } from 'react-daisyui';
import { useField } from 'formik';
import Text from '../Text';
import { classes } from 'utils/tailwind';

const FormikTextArea: FC<FormikTextAreaProps> = ({
  label,
  className,
  name,
  labelContainer,
  fieldContainer,
  id,
  rows,
  textAreaClassName,
  disabled = false,
  required = false,
  ...FormikTextAreaProps
}): JSX.Element => {
  const [field, meta, helpers] = useField(name);

  return (
    <div className="">
      <div className={cn(`flex flex-col gap-2 ${className}`)}>
        {label && (
          <div className={labelContainer}>
            <label
              className={classes([required ? 'required' : ''])}
              htmlFor={id}>
              {label}
            </label>
          </div>
        )}
        <div className={fieldContainer}>
          <Textarea
            {...FormikTextAreaProps}
            disabled={disabled}
            value={field.value ?? ''}
            onChange={(e) => helpers.setValue(e.target?.value)}
            className={cn(
              `textarea textarea-bordered w-full md:resize-y m-0 ${textAreaClassName}`,
            )}
            rows={rows ?? 2}
          />
        </div>
      </div>
      {meta.error && meta.touched && (
        <Text className="text-red-500 mt-0">{meta.error}</Text>
      )}
    </div>
  );
};

export default FormikTextArea;

import React from 'react';

import AppRoutes from 'routes';
import AppProvider from 'providers/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { extractTenantFromUrl } from 'utils/site';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'utils';
import 'types/extensions/string';
import { enableMapSet } from 'immer';
enableMapSet();

extractTenantFromUrl();
function App() {
  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <ToastContainer />
      </QueryClientProvider>
    </AppProvider>
  );
}

export default App;

import React, { FC } from 'react';

import { FormikSelectProps } from './FormikSelect.props';
import { Field, useField } from 'formik';
import { cn } from 'utils';
import Text from '../Text';

const FormikSelectView: FC<FormikSelectProps> = ({
  label,
  className,
  name,
  children,
  labelContainer,
  fieldContainer,
  defaultValue,
  value,
  fieldClass,
  hideError = false,
  id,
  disabled = false,
  onChange,
  isLoading = false,
  required = false,
}): JSX.Element => {
  const [field, meta, helpers] = useField(name);

  return (
    <div className={cn(`flex flex-col gap-2 ${className}`)}>
      <div className={labelContainer}>
        <label htmlFor={id}>
          {label} {required && <span className="text-red-500">(*)</span>}
        </label>
      </div>
      <div className={cn(`flex flex-col ${fieldContainer} `)}>
        <select
          disabled={disabled}
          id={id}
          name={name}
          defaultValue={defaultValue}
          value={field.value !== '' ? value : field.value}
          onChange={async (e: any) => {
            helpers.setValue(e.target?.value || '');
            onChange && onChange(e?.target?.value);
          }}
          className={cn(
            `select select-bordered w-full text-secondary h-10 ${fieldClass}`,
          )}>
          {!isLoading && children}
        </select>
        {hideError === false && meta.error && meta.touched && (
          <Text className="text-red-500 mt-1">{meta.error}</Text>
        )}
      </div>
    </div>
  );
};

export default FormikSelectView;

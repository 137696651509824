import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { fetchDamagedItems, fetchItems } from 'services/items';
import { Item, RowItem } from 'types/item';
import { SortProps } from 'types/tables';

export const useItems = ({
  page,
  limit,
  sort,
  search,
  clients,
  categories,
  isArchived,
}: {
  page?: number;
  limit?: number;
  sort?: string;
  search?: string;
  clients?: string[];
  isArchived?: boolean;
  categories?: string[];
} = {}) => {
  const [loading, setLoading] = useState(true); // Start with loading state as true
  const [itemsData, setItemsData] = useState<RowItem[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [sortBy, setSortBy] = useState('_id:desc');
  const [totalPages, setTotalPages] = useState(0);
  const [type, setType] = useState<1 | 2>(1); // 1 = Damaged, 2 = Repair
  const [damaged, setDamaged] = useState(false);

  const queryObject = damaged
    ? {
        queryKey: [
          'FECTH-DAMAGED-ITEMS',
          page,
          sortBy,
          clients,
          categories,
          search,
          type,
          isArchived,
        ],
        queryFn: () => {
          setLoading(true);
          return fetchDamagedItems({
            page,
            limit,
            sortBy: sortBy,
            search,
            clients,
            isArchived,
            categories,
            type,
          });
        },
        staleTime: Infinity,
      }
    : {
        queryKey: [
          'FECTH-ITEMS',
          page,
          sortBy,
          clients,
          categories,
          search,
          isArchived,
        ],
        queryFn: () => {
          setLoading(true);
          return fetchItems({
            page,
            limit,
            sortBy: sortBy,
            search,
            clients,
            isArchived,
            categories,
          });
        },
        staleTime: Infinity,
      };

  const { data, isSuccess } = useQuery(queryObject);

  useEffect(() => {
    if (isSuccess && data) {
      setItems(data.items);
      setTotalPages(data.totalPages);
      setLoading(false);
    }
  }, [isSuccess, data]);

  const sortDataBy = async (sort: SortProps<Item>) => {
    if (sort) {
      setSortBy(`${sort.field}:${sort.order}`);
    }
  };

  const checkAllElements = () => {
    setItemsData((items) => {
      return items.map((item) => ({
        ...item,
        isChecked: !isAllChecked,
      }));
    });

    setIsAllChecked((prev) => !prev);
  };

  return {
    items,
    loading,
    sortDataBy,
    checkAllElements,
    totalPages,
    setDamaged,
    damaged,
    setType,
  };
};

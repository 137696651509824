export const QUERY_KEYS = {
  FETCH_ITEM_DETAILS: 'FETCH-ITEM-DETAILS',
  FETCH_PROJECT_DETAILS: 'FETCH_PROJECT_DETAILS',
  FETCH_ITEM_ADJUST_QUANTITY_HISTORY: 'FETCH_ITEM_ADJUST_QUANTITY_HISTORY',
  FETCH_ITEMS: 'FETCH_ITEMS',
  FETCH_PROJECTS: 'FETCH_PROJECTS',
  FETCH_PROJECT_ITEMS_TO_SHIP: 'FETCH_PROJECT_ITEMS_TO_SHIP',
  FETCH_PROJECT_CRATE_ITEMS: 'FETCH_PROJECT_CRATE_ITEMS',
  FETCH_CRATES_BY_PROJECT: 'FETCH_CRATES_BY_PROJECT',
};
